<template>
  <div :style="'opacity: ' + (initFinished ? '1' : '0')">
    <div class="index-box">
      <div class="justify-content-space-between align-items-center" style="max-width: 1200px;width: 100%;">
        <img src="../assets/images/icon_logo@2x.png" class="w-96 h-32">
        <div class="align-items-center pd-t-12 pd-b-12">
          <div class="mg-r-40 cursor-pointer fs-14" style="color:#202020;font-weight: 600;">首页</div>
          <!-- <div class="mg-r-40 fs-14 shop-text" @click="handleToShop">卡片商店</div> -->
          <div class="mg-r-40 fs-14 shop-text" @click="onClick">产品手册</div>
          <el-popover placement="bottom" title="扫码加入惊叹交流群" width="300" trigger="hover">
            <img src="../../public/static/image/wxqun.jpg" alt="" class="w-fill h-fill">
            <div class="fs-14  cursor-pointer" slot="reference" style="color: rgba(32, 32, 32, 0.6);">微信交流群</div>
          </el-popover>
          <div class="mg-l-40">
            <el-button plain style="background-color: #f7f7f7;border-radius: 12px;width: 120px;" @click="toHomeClick">
              进入学习台
            </el-button>
          </div>
        </div>
      </div>
      <div class="display-flex align-items-center justify-content-space-between " style="width: 1200px;">
        <div class="flex-1 position-relative">
          <img src="../assets/images/home_bg_cclor@2x.png" alt="" class="position-absolute w-568 h-384"
            style="top:-76px;left:-60px;z-index: -1;">
          <h1 class="fs-44 lh-62">做笔记、背书、背单词<br>就用闪记卡</h1>
          <div class="lh-28 c-9 w-440 fs-14">
            闪记卡是一款将笔记、记忆和知识管理融合于一身的新型学习工具和教育工具，不只是记录，更重要的帮你吸收知识，闪记卡是通用型工具，不限于学英语、语文、计算机、法律、中西医等，在应试提分上有显著效果。
          </div>
          <div class="display-flex align-items-center mg-t-20">
            <div class="mg-r-16">支持：</div>
            <div class="down-load-icon-box">
              <div class="down-load-icon" @click="downLoadClick(index)" v-for="(item, index) in downLoadList"
                :key="index">
                <svg-icon :iconClass="item.icon" class="w-24 h-24 mg-r-4"></svg-icon>
                <div class="download-text">{{ item.name }}</div>
              </div>
            </div>
          </div>
          <div class="display-flex mg-t-48 ">
            <el-button type="primary" class="w-186 h-48 bdr-12 fs-16" @click="onDownloadClientClick">下载客户端</el-button>
            <div class="mg-l-10">
              <el-button plain style="background-color: #f7f7f7" class="h-48 mg-l-10 fs-16 bdr-12 w-186"
                @click="toHomeClick">
                进入学习台
              </el-button>
            </div>
          </div>
        </div>
        <div class="w-672 position-relative" style="right: -26px;">
          <img src="../assets/images/index/web_image_a@2x.png" class="w-fill h-546">
        </div>
      </div>
    </div>
    <ProductIntroduce :isTextOnLeft="false">
      <template v-slot:text>
        一步速记<br>
        捕捉灵感<br>
        知识点、生词
      </template>
      <template v-slot:desc>
        <div class="w-450">
          打开闪记卡只需一步，就能完成记录，输入单词会自动补全释义和发音，输入灵感和知识点会自动选择卡片模板，速记卡片默认保存到闪记盒以便后续统一管理，这一切都十分省心！
        </div>
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_b@2x.png" alt="" class="w-662 h-464">
      </template>
    </ProductIntroduce>
    <ProductIntroduce background="#f7f7f7" :needRight="false">
      <template v-slot:text>
        个性化记忆算法<br>
        有效记住单词、知识点<br>
        抵抗遗忘
      </template>
      <template v-slot:desc>
        <div class="w-448">
          人脑可不像计算机，拷贝一份就能记下来，想把课本知识放进脑子里，你需要通过对知识点重复的回忆才能往人脑里成功写入数据，记忆秘籍就是回忆+重复，闪记卡的闪卡和间隔重复算法，很好地为你解决了这个问题，用闪卡帮你回忆知识，用间隔算法重复安排复习，高效帮你把知识写进大脑。
        </div>
      </template>
      <template v-slot:video>
        <div class="w-554 h-616 bg-f"
          style="box-shadow: 0px 2px 40px 0px rgba(0,0,0,0.0300);border-radius: 30px;margin: auto 0;">
          <img src="../assets/images/index/web_image_c@2x.png" alt="">
        </div>
      </template>
    </ProductIntroduce>
    <ProductIntroduce>
      <template v-slot:text>
        大纲闪卡<br>
        读书笔记结构化<br>
      </template>
      <template v-slot:desc>
        <div class="w-450">
          在学习计算机、心理学等专业课时，零散的记录无法让我有体系地整理和回顾知识点，用闪记卡大纲功能，可以分章节、分层级地用闪卡记录知识，相比传统长篇笔记，变得更加灵活。
        </div>
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_d@2x.png" alt="" class="w-662 h-464">
      </template>
    </ProductIntroduce>
    <!-- 管理功能 -->
    <ProductIntroduce :isTextOnLeft="false" background="#f7f7f7">
      <template v-slot:text>
        强大的管理功能<br>掌握程度、标记、标<br>签、双链
      </template>
      <template v-slot:introduceBtn>
        <div class="h-54 bdr-10 fs-16 w-256 text-align-center lh-54" style="background: #FFB700;color: #fff;">
          用文件夹和标签知识分类
        </div>
        <div class="h-54 bdr-10 fs-16 w-324 text-align-center lh-54 mg-t-20" style="background: #FFB700;color: #fff;">
          用双链链接知识｜用标记分层知识
        </div>
        <div class="h-54 bdr-10 fs-16 w-256 text-align-center lh-54 mg-t-20" style="background: #FFB700;color: #fff;">
          用掌握程度了解学习情况
        </div>
        <div class="h-54 bdr-10 fs-16 w-342 text-align-center lh-54 mg-t-20" style="background: #202020;color:#FFC532">
          你还可以排序、批量移动、停学卡片
        </div>
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_e@2x.png" alt="" class="w-662 h-464">
      </template>
    </ProductIntroduce>
    <!-- 双链 -->
    <ProductIntroduceChain></ProductIntroduceChain>
    <!-- 模板 -->
    <ProductIntroduceModel></ProductIntroduceModel>
    <!-- 智能模版 -->
    <ProductIntroduceWisdom>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_g@2x.png" alt="" class="w-fill mg-t-40">
      </template>
    </ProductIntroduceWisdom>
    <!-- 智能单词卡 -->
    <ProductIntroduce :isTextOnLeft="false" background="#f7f7f7">
      <template v-slot:text>
        智能单词卡<br>
        支持100+语言<br>
        制卡从未如此简单
      </template>
      <template v-slot:desc>
        <div class="w-418">
          史上最强单词卡，只需输入单词/短语，自动补全释义、发音，制卡效率N倍提升，支持100多种语言，无论英语、日语、德语、韩语、西班牙语……全支持。 神经网络级语音合成，拟真人发音，支持添加个人笔记、助记，一键用专业词典软件查词。
        </div>
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_h@2x.png" alt="" class="w-574">
      </template>
    </ProductIntroduce>
    <!-- 编辑器介绍 -->
    <ProductIntroduce>
      <template v-slot:text>
        功能完善的编辑器<br>
        基础排版文字<br>
        挖空、插入代码<br>
        图片、Markdown
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_i.png" alt="" class="w-fill h-508">
      </template>
    </ProductIntroduce>
    <!-- 微信 -->
    <ProductIntroduce :isTextOnLeft="false" background="#f7f7f7">
      <template v-slot:video>
        <img src="../assets/images/index/web_image_j.png" alt="" class="w-610 h-584">
      </template>
      <template v-slot:text>
        微信聊天输入笔记<br>
        同步到闪记卡<br>
      </template>
      <template v-slot:desc>
        <div class="w-448">
          微信无疑是当下每个人使用频率最高的应用，无论是在与人聊天，还是阅读订阅号，过程当中的每一点灵思与收获，你都可以直接在公众号“惊叹闪卡笔记”中记录，自动同步到闪记卡
        </div>
      </template>
    </ProductIntroduce>
    <!-- 图片遮挡 -->
    <ProductIntroduce>
      <template v-slot:text>
        图片遮挡
        <div class="fs-18" style="color:rgba(32, 32, 32, .6)">可将图片快速插入、任意遮挡</div>
      </template>
      <template v-slot:introduceBtn>
        <div class="h-54 bdr-10 fs-16 w-266 text-align-center lh-54" style="background: #FFB700;color: #fff;">
          添加图片，将图片添加至编辑器
        </div>
        <div class="h-54 bdr-10 fs-16 w-216 text-align-center lh-54 mg-t-20" style="background: #FFB700;color: #fff;">
          选中图片，点击标注遮挡
        </div>
        <div class="h-54 bdr-10 fs-16 w-380 text-align-center lh-54 mg-t-20" style="background: #FFB700;color: #fff;">
          选择任意颜色的矩形或笔刷进行遮挡，点击完成
        </div>
      </template>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_m@2x.png" alt="" class="w-640 h-444">
      </template>
    </ProductIntroduce>
    <!-- 导入 -->
    <ProductIntroduce :isTextOnLeft="false" background="#f7f7f7">
      <template v-slot:video>
        <img src="../assets/images/index/web_image_k.png" alt="" class="w-662 h-464">
      </template>
      <template v-slot:textdownImg>
        <img src="../assets/images/index/image_list.png" alt="" class="w-fill position-relative mg-t-8"
          style="left: -26px">
      </template>
      <template v-slot:text>
        强大的笔记导入功能<br>
        能适配市面常见笔记、<br>
        脑图产品
      </template>
      <template v-slot:desc>
        <div class="w-442">
          闪记卡拥有非常强大的导入功能，支持Anki、Excel、微信笔记、脑图、大纲笔记的批量导入，同时还支持利用字段分隔符和卡片分隔符在线批量制作卡片，制卡效率倍速提升。
          批量制卡或者导入卡片，如果你需要对某些内容进行挖空，你可以通过！！挖空内容！！双叹号进行挖空，导入时会自动识别。
        </div>
      </template>
    </ProductIntroduce>
    <!-- 商店 -->
    <!-- <ProductIntroduce>
      <template v-slot:video>
        <img src="../assets/images/index/web_image_l.png" alt="" class="w-654">
      </template>
      <template v-slot:text>
        惊叹商店<br>
        100000+闪卡资源<br>
        覆盖所有学科
      </template>
      <template v-slot:desc>
        <div class="w-442">
          在商店你可以下载现成的闪卡资源，快速开始学习，节约你的制卡时间，目前涵盖考研、考公、自考、大学、高中、初中、小学、单词等资源，你也可以将自己制作的闪卡分享到商店，帮助更多人学会用闪卡学习。
        </div>
      </template>
    </ProductIntroduce> --> 
    <DownLoadFooter @onDownloadClick="onDownloadClick"></DownLoadFooter>
    <el-dialog :visible.sync="showDownloadQr" width="400px">
      <div class="text-align-center" v-show="currentIndex === 1">
        <div class="c-app-yellow">请使用手机扫码下载APP</div>
        <vue-qr text="https://amazingmemo.com/preDownload?from=pc" :size="250"></vue-qr>
      </div>
      <div class="text-align-center" v-show="currentIndex === 0">
        <!-- <div class="mg-b-10 c-app-yellow">微信小程序搜索闪记卡APP, 或者扫码使用</div> -->
        <div class="c-app-yellow">请使用手机扫码下载APP</div>
        <img src="../assets/images/download_app_ios.jpg" alt="" class="w-250 h-250">
        <!-- <div class="fs-12 c-8 mg-t-16">*闪记卡新版APP，即将上架AppStore，敬请期待</div> -->
      </div>
    </el-dialog>
  </div>
</template>
<script>

import DownLoadFooter from './Index/DownLoadFooter.vue'
import ProductIntroduce from './Index/ProductIntroduce.vue'
import IntroduceComponent from './Index/components/IntroduceComponent.vue'
import ProductIntroduceModel from './Index/ProductIntroduceModel.vue'
import ProductIntroduceWisdom from './Index/ProductIntroduceWisdom.vue'
import ProductIntroduceChain from './Index/ProductIntroduceChain.vue'

import VueQr from 'vue-qr'
import { isMobile } from '../libs/util'

import md5 from 'md5'
export default {
  components: {
    VueQr,
    DownLoadFooter,
    ProductIntroduce,
    IntroduceComponent,
    ProductIntroduceWisdom,
    ProductIntroduceModel,
    ProductIntroduceChain
  },
  data() {
    return {
      beian: '粤ICP备2022002259号',
      showAbout: false,
      showDownloadQr: false,
      initFinished: false,
      currentIndex: 0,
      downLoadList: [
        { name: "Windows", icon: 'windows_small' },
        { name: "MacOS", icon: 'macos_small' },
        { name: "iOS", icon: 'ios_small' },
        { name: "Android", icon: 'android_small' },
      ]
    }
  },
  methods: {
    downLoadClick(index) {
      if (index === 0) {
        window.open('https://mniiz2uj96.feishu.cn/file/boxcnh1VCPjd1t79FOXf0VBejHf')
      } else if (index === 1) {
        window.open('https://mniiz2uj96.feishu.cn/file/boxcnEDEII5uQbxByKHy2Ig3IOd')
      } else if (index === 2) {
        this.showDownloadQr = true;
        this.currentIndex = 0
        // window.open("https://apps.apple.com/cn/app/id6446388620")
      } else {
        this.showDownloadQr = true;
        this.currentIndex = 1

      }
    },
    handleToShop() {
      if (this.$cookies.get('authToken')) {
        window.open("https://store.amazingmemo.com?token=" + this.$cookies.get('authToken'))
      } else {
        window.open("https://store.amazingmemo.com")
      }
    },
    onClick() {
      window.open("https://amazenote.cn/")
    },
    toHomeClick() {
      location.href = 'https://amazingmemo.com/home/studyPlatform/cardAll'
      // this.$router.push('/home/studyPlatform/cardAll')
    },
    onDownloadClick(index) {
      this.showDownloadQr = true;
      this.currentIndex = index
    },
    onDownloadClientClick() {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      })
    }
  },
  created() {
    setTimeout(() => {
      this.initFinished = true;
    }, 100);
  },
  mounted() {
    if (isMobile() === 1) {
      // 移动端
      // this.$router.push('/h5')
      location.href = 'https://www.amazingmemo.com/h5'
    }
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }
}
</script>

<style lang="scss" scoped>
.shop-text {
  color: rgba(32, 32, 32, 0.6);

  &:hover {
    color: #ffb700;
    cursor: pointer;
  }
}

.index-box {
  // margin: 0 auto;
  padding-left: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .down-load-icon-box {
    display: flex;

    .down-load-icon {
      display: flex;
      align-items: center;
      margin-right: 20px;
      font-size: 12px;
      color: #202020;

      &:hover {
        cursor: pointer;
        color: #ffb700 !important;
      }
    }
  }
}

::v-deep .el-dialog {
  border-radius: 5px;
}
</style>
